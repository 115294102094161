import React from 'react'
import { ThemeProvider } from 'styled-components'
import { theme, GlobalStyles, helpers } from '../theme'

export default ({ children }) => {
  return (
    <ThemeProvider theme={{ ...theme, ...helpers }}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  )
}
